.morphing-menu {
  position: relative;
}

.menu-label {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50px;
  cursor: pointer;
  position: relative;
  z-index: 1000;
}

.menu-label span {
  background: #000;
  border-radius: 10px;
  height: 4px;
  margin: 5px 0;
  transition: transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6),
    width 0.4s ease;
}

span:nth-of-type(1) {
  width: 100%;
}

span:nth-of-type(2) {
  width: 80%;
}

span:nth-of-type(3) {
  width: 60%;
}

input[type='checkbox'] {
  display: none;
}

/* Эффект при наведении */
label:hover span:nth-of-type(2) {
  width: 100%; /* Увеличиваем ширину */
}

label:hover span:nth-of-type(3) {
  width: 100%; /* Увеличиваем ширину */
}

/* Скрываем чекбокс */
input[type='checkbox'] {
  display: none;
}

/* Превращение в крестик */
.morphing-menu.open .menu-label span:nth-of-type(1) {
  transform: rotate(45deg);
  transform-origin: left;
  width: 75%;
}

.morphing-menu.open .menu-label span:nth-of-type(2) {
  opacity: 0;
  transition: 0.2s ease-out;
}

.morphing-menu.open .menu-label span:nth-of-type(3) {
  transform: rotate(-45deg);
  transform-origin: left;
  width: 75%;
}

/* Навигационное меню */
.menu {
  position: absolute;
  /* top: -100px; */
  /* left: 0; */
  /* width: 100%; */
  width: 300px;
  right: 0;
  background-color: #333;
  color: white;
  padding: 24px;
  transform: translateY(-100%);
  transition: transform 0.5s ease;
  display: none;
}

.menu.visible {
  transform: translateY(0);
  transition: transform 0.5s ease;
  display: block;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu ul li {
  margin: 20px 0;
}

.menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 24px;
}
